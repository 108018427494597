<template>
  <div
    class="moment-images-control"
    @dragover.prevent="setDropzoneState(true)"
    @dragleave="setDropzoneState(false)"
    @drop.prevent="handleDrop"
    @click="setDropzoneState(false)">
    <div
      v-if="showDropzoneOverlay"
      class="moment-images-control__dropzone-overlay">
      Drop your images here
    </div>
    <div class="moment-images-control__heading">
      <span class="h5 m-0 color-dark font-weight-semi-bold">Image</span>
      <span v-if="images.length" class="font-size-12 text-mute">{{ counterText }}</span>
      <div v-if="images.length && images.length < MAX_FILES" :class="['btn btn-xs btn-primary gap-2 position-relative', { disabled }]">
        <input type="file"
          ref="file"
          class="moment-images-control__add-btn"
          multiple
          :accept="acceptTypes"
          :disabled="disabled"
          @change="onFileChange">
        <i class="fa-regular fa-image" />
        Add photo
      </div>
    </div>
    <div class="moment-images-control__list">
      <div v-if="!images.length" class="d-flex flex-column align-items-center text-center">
        <p>
          Select a file from your device or drag it over. You can upload 10 more images.
        </p>
        <div :class="['btn btn-primary gap-2 position-relative mb-4', { disabled }]">
          <input type="file"
            ref="file"
            class="moment-images-control__add-btn"
            multiple
            :accept="acceptTypes"
            :disabled="disabled"
            @change="onFileChange">
          <i class="fa-regular fa-image" />
          Add photo
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center gap-4 mb-3">
        <p class="mb-0">
          Uploaded images can be collaged <br>
          to showcase the “Before/After” result.
        </p>
        <img :src="require('mv/collage_explain.svg')" alt="">
      </div>
      <div v-if="!images.length && !hasOpenaiKey" class="d-flex flex-column align-items-center text-center">
        <p class="mb-0 text-warning">
          <i class="far fa-sparkles" />
          To AI generate image descriptions add OpenAI key in <a href="/integrations" target="_blank">organization settings</a>.
        </p>
      </div>
      <span class="d-block mb-1 font-size-14 font-weight-semi-bold text-primary">Cover image</span>

      <div v-if="collageState.state === COLLAGE_STATES.FINISHED" class="moment-images-control__item cover collage">
        <div class="moment-images-control__left">
          <div :class="['moment-images-control__collage', collageState.imgs[0].aspectRatio > 1 ? 'horizontal' : 'vertical']">
            <div class="collage-before">
              <img :src="collageState.imgs[0].preview || collageState.imgs[0].url" v-bind="halfCollageSizes" alt="">
              <p>Before</p>
            </div>
            <div class="collage-after">
              <img :src="collageState.imgs[1].preview || collageState.imgs[1].url" v-bind="halfCollageSizes" alt="">
              <p>After</p>
            </div>
          </div>
        </div>
        <div class="form-field flex-1">
          <textarea v-model="collageState.imgs[0].description" :disabled="disabled" :readonly="disabled" rows="6" />
        </div>
        <button class="btn btn-outline-danger" type="button" :disabled="disabled" @click.prevent="onResetCollageClicked">
          <i class="fa-regular fa-fw fa-arrows-rotate mr-2" />
          Reset
        </button>
      </div>

      <draggable
        v-if="images.length"
        v-model="internalImages"
        handle=".fa-grip-dots-vertical"
        :disabled="disabled"
        @change="$emit('setImages', images)">
        <ImagesItem
          v-for="(image, index) in filteredInternalImages"
          :key="image.url"
          :image="image"
          :position="index"
          :disabled="disabled"
          :ai-messages="aiMessages"
          :collage-state="collageState"
          :class="{ cover: index === 0 && collageState.state !== COLLAGE_STATES.FINISHED}"
          @updateImage="(fields, clearBefore = false) => updateImage(image, fields, clearBefore)"
          @removeImage="removeImage(image)" />
      </draggable>
      <div v-else class="moment-images-control__item cover p-4">
        <span class="text-mute font-size-12">The first image uploaded will be used as the cover image.</span>
      </div>
    </div>
    <span v-if="anyDuplicateImage" class="text-warning">
      Some of your images were already used
    </span>
  </div>
</template>

<script>
import axios from 'axios'
import { md5 } from 'js-md5'
import axiosTransform from 'common/axios'
import draggable from 'vuedraggable'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'
import ImagesItem from './images_item.vue'
import { COLLAGE_STATES } from '../helpers'

const generateMd5Hash = async(imageFile) => {
  const buffer = await imageFile.arrayBuffer()
  return md5(buffer)
}

async function readImage(file) {
  const hash = await generateMd5Hash(file)

  return new Promise((resolve) => {
    const imgPreview = new Image()

    imgPreview.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = imgPreview
      const aspectRatio = width / height
      resolve({
        file,
        md5: hash,
        url: imgPreview.src,
        collageBefore: false,
        collageAfter: false,
        aspectRatio,
        duplicate: false
      })
    }

    imgPreview.src = URL.createObjectURL(file)
  })
}

export default {
  components: { draggable, ImagesItem },
  props: {
    images: { type: Array, required: true },
    acceptTypes: { type: String, default: 'image/png, image/jpg, image/jpeg' },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      aiMessages: [],
      showDropzoneOverlay: false
    }
  },
  created() {
    this.MAX_FILES = 10
    this.MAX_FILE_SIZE = 100 * 1024 * 1024 // 100 mb
    this.COLLAGE_STATES = COLLAGE_STATES
    this.hasOpenaiKey = Styxie.USER.hasOpenaiKey

    this.channel = subscribe('DescribeImageChannel', (msg) => {
      this.aiMessages.push(msg)
    })
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  async mounted() {
    const imagesWithDuplicationMarks = await this.checkForDuplicates(this.images)
    this.internalImages = imagesWithDuplicationMarks
  },
  computed: {
    internalImages: {
      get() {
        return this.images
      },
      set(val) {
        this.$emit('setImages', val)
      }
    },
    filteredInternalImages() {
      return this.internalImages.filter((image) => !(this.collageState.state === this.COLLAGE_STATES.FINISHED && this.collageState.imgs.includes(image)))
    },
    counterText() {
      const count = this.MAX_FILES - this.images.length
      return `You can upload ${count} more image${count !== 1 ? 's' : ''}.`
    },
    collageState() {
      if (this.images.length < 2) {
        return { state: this.COLLAGE_STATES.DISABLED }
      }

      let beforeSelected
      let afterSelected

      this.images.forEach((img) => {
        if (img.collageBefore) {
          beforeSelected = img
        } else if (img.collageAfter) {
          afterSelected = img
        }
      })

      if (beforeSelected && afterSelected) {
        return {
          state: this.COLLAGE_STATES.FINISHED,
          aspectRatio: beforeSelected.aspectRatio,
          imgs: [beforeSelected, afterSelected]
        }
      } if (beforeSelected) {
        return {
          state: this.COLLAGE_STATES.BEFORE_SELECTED,
          aspectRatio: beforeSelected.aspectRatio,
          imgs: [beforeSelected, null]
        }
      } if (afterSelected) {
        return {
          state: this.COLLAGE_STATES.AFTER_SELECTED,
          aspectRatio: afterSelected.aspectRatio,
          imgs: [null, afterSelected]
        }
      }

      return {
        state: this.COLLAGE_STATES.INITIAL,
        aspectRatio: null,
        imgs: [null, null]
      }
    },
    halfCollageSizes() {
      if (this.collageState.imgs[0].aspectRatio > 1) {
        return {
          height: parseInt(328 / this.collageState.imgs[0].aspectRatio, 10)
        }
      }

      return {
        width: parseInt(219 * this.collageState.imgs[0].aspectRatio, 10)
      }
    },
    anyDuplicateImage() {
      return this.images.find((image) => image.duplicate)
    }
  },
  methods: {
    async checkForDuplicates(images) {
      if (!images?.length) return []
      const { data: duplicates } = await axios.post('/gb/ajax/moments/existing_media', {
        images: images.map(({ id: obfuscatedId, md5: mediaHash }) => ({ obfuscatedId, mediaHash }))
      }, axiosTransform)

      const imagesWithDuplicationMarks = duplicates.map((duplicate, index) => {
        images[index].duplicate = duplicate
        return {
          ...images[index],
          duplicate
        }
      })

      return imagesWithDuplicationMarks
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        return
      }

      const wrongFormatFiles = []
      const bigImages = []
      const filteredFiles = Array.from(files).filter(({ name, type, size }) => {
        if (!this.acceptTypes.includes(type)) {
          wrongFormatFiles.push(name)
          return false
        }
        if (size > this.MAX_FILE_SIZE) {
          bigImages.push(name)
          return false
        }

        return true
      }).slice(0, this.MAX_FILES - this.images.length)

      if (wrongFormatFiles.length > 0) {
        toastr.warning(`Can't upload files:<br>${wrongFormatFiles.join('<br>')}`, { timeOut: 3000 })
      }

      if (bigImages.length > 0) {
        toastr.warning(`The image size exceeds 100mb - you will not be able to upload it.<br>${bigImages.join('<br>')}`)
      }

      this.$refs.file.value = ''
      if (!/safari/i.test(navigator.userAgent)) {
        this.$refs.file.type = ''
        this.$refs.file.type = 'file'
      }

      this.createImages(filteredFiles)
    },
    async createImages(files) {
      const newImages = await Promise.all(files.map((file) => readImage(file)))
      const imagesWithDuplicationMarks = await this.checkForDuplicates(newImages)
      this.$emit('setImages', [...this.images, ...imagesWithDuplicationMarks])
    },
    removeImage(image) {
      this.$emit('setImages', this.internalImages.filter((img) => img !== image))
    },
    onResetCollageClicked() {
      this.$emit('setImages', this.images.map((img) => {
        if (this.collageState.imgs.includes(img)) {
          return {
            ...img,
            collageAfter: false,
            collageBefore: false,
            description: ''
          }
        }

        return img
      }))
    },
    updateImage(image, imageFields, clearBefore) {
      this.$emit('setImages', this.images.map((img) => {
        if (img === image) {
          return { ...image, ...imageFields }
        }

        if (img.collageBefore && clearBefore) {
          return { ...img, description: '' }
        }

        return img
      }))
    },
    setDropzoneState(state) {
      if (this.images.length === this.MAX_FILES || this.disabled) {
        this.showDropzoneOverlay = false
        return
      }
      this.showDropzoneOverlay = state
    },
    handleDrop(dropEvent) {
      this.showDropzoneOverlay = false
      this.onFileChange(dropEvent)
    }
  }
}
</script>
